import Link from 'next/link';

import { AlertIcon } from '@shared/ui-components';

import styles from './not-found-content.module.scss';

export const NotFoundContent = () => {
  const linkLabel = 'Go back to Homepage';
  const message = 'Page was not found.';

  return (
    <div className={styles['not-found-content']}>
      <AlertIcon className={styles['not-found-content__icon']} />
      <p className={styles['not-found-content__message']}>{message}</p>
      <Link className={styles['not-found-content__link']} href="/">
        {linkLabel}
      </Link>
    </div>
  );
};
