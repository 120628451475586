'use client';

/* eslint-disable import/no-unassigned-import */
import '../styles/global/_index.scss';
import '../styles/tokens/root-variables.css';
import '../styles/tokens/root-variables-overrides.scss';
/* eslint-enable import/no-unassigned-import */
import classNames from 'classnames';
import { Merriweather, Nunito_Sans } from 'next/font/google';

import { NotFoundContent } from '@module/mdrt-org/shared/page-content-components';

const merriweather = Merriweather({
  subsets: ['latin'],
  variable: '--mdrt-font-families-merriweather',
  weight: ['400', '700', '900'],
});
const nunito = Nunito_Sans({
  adjustFontFallback: false,
  display: 'swap',
  fallback: ['Arial', 'sans-serif'],
  subsets: ['latin'],
  variable: '--mdrt-font-families-nunito-sans',
  weight: ['400', '600'],
});

const NotFound = () => {
  return (
    <html lang="en">
      <body className={classNames(merriweather.variable, nunito.variable)}>
        <NotFoundContent />
      </body>
    </html>
  );
};

export default NotFound;
